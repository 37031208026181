<template>
  <div class="rounded">
    <button @click="zoomIn" :class="{ 'bg-gray-100 text-gray-300 cursor-not-allowed': state.zoom === 3, 'bg-sail hover:bg-big-stone hover:text-white': state.zoom < 3 }" class="w-6 h-6 flex items-center justify-center rounded-l font-bold focus:outline-none border-r border-blumine border-opacity-10">
      <icon-plus class="w-4 h-4" />
    </button>
    <button @click="zoomOut" :class="{ 'bg-gray-100 text-gray-300 cursor-not-allowed': state.zoom === 0, 'bg-sail hover:bg-big-stone hover:text-white': state.zoom > 0 }" class="w-6 h-6 flex items-center justify-center rounded-r font-bold focus:outline-none">
      <icon-minus class="w-4 h-4" />
    </button>
  </div>
</template>

<script>
import { reactive, watch } from 'vue'
import { IconPlus, IconMinus } from '@/components/icons'

export default {
  name: 'charts-zoom-nav',

  components: {
    IconPlus,
    IconMinus,
  },

  emits: ['zoom'],

  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 3
    },
    defaultZoom: {
      type: Number,
      default: -1
    }
  },

  setup(props, { emit }) {
    const state = reactive({
      zoom: props.defaultZoom === -1 ? props.max : props.defaultZoom,
    })

    const zoomIn = () => {
      if (state.zoom < props.max) {
        state.zoom++
        emit('zoom', state.zoom)
      }
    }

    const zoomOut = () => {
      if (state.zoom > props.min) {
        state.zoom--
        emit('zoom', state.zoom)
      }
    }

    watch(
      () => props.defaultZoom,
      () => {
        state.zoom = props.defaultZoom
        emit('zoom', state.zoom)
      }
    )

    return {
      zoomIn,
      zoomOut,
      state,
    }
  }
}
</script>
