<template>
  <chart-card :is-loading="isLoading" :title="title">
    <div class="px-2 py-4 relative">
      <chart
        type="line"
        :labels="data.labels"
        :data="data.values"
        :options="options"
        height="250"
      />
    </div>
  </chart-card>
</template>

<script>
import { computed } from 'vue'
import money from '@/helpers/money.js'
import Chart from '@/components/charts/Chart.vue'
import ChartCard from '@/components/reports/ChartCard.vue'
import ZoomNav from '@/components/charts/ZoomNav.vue'

export default {
  components: {
    Chart,
    ChartCard,
    ZoomNav,
  },

  props: ['title', 'data', 'isLoading', 'money'],

  setup(props) {
    const options = computed(() => {
      return {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.datasets[tooltipItem.datasetIndex].label
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              return label + ': ' + (props.money ? money.format(value) : value)
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: (v) => (props.money ? money.format(v, 0) : v)
            }
          }]
        }
      }
    })

    return {
      options,
    }
  }
}
</script>
