<template>
  <layout>

    <template #secondarySidebar>
      <reports-nav />
    </template>

    <reports-toolbar
      title="Annual Report"
      v-model:filters="state.filters"
      :machines="state.machines"
      @update-filters="updateFilters"
      :hide-date-range="true"
    />

    <!-- Content -->
    <div v-if="state.isLoading" class="flex flex-col items-center p-4 text-center">
      <div class="flex items-center">
        <icon-loading class="w-6 h-6 text-blumine mr-3" /> Loading data...
      </div>
    </div>
    <div v-else-if="state.apiError">
      <alert-response-warning :error="state.apiError" />
    </div>
    <div v-else>
      <div class="flex flex-col space-y-4 mt-6 xl:mt-6">
        <div v-if="state.tables.length === 0">
          No Data For Selected Filter
        </div>

        <div>
          <totals-chart
            title="Totals"
            :data="state.chartData"
            :is-loading="false"
            :money="state.dataKey === 'total_sales'"
          />
        </div>

        <div class="flex justify-between">
          <div class="flex items-center">
            <export-action-buttons :data="exportData" :filter="exportFilterDesc" filename="AnnualReport" />
          </div>
          <div class="w-2/3 md:w-1/3 flex flex-col">
            <div class="flex mb-2">
              <ul class="flex divide-x divide-gray-200 shadow flex-1">
                <li
                  v-for="(val, id) in state.dataKeyOptions"
                  :key="id"
                  class="w-full"
                >
                  <button
                    @click="state.dataKey = val"
                    class="inline-block relative py-2 px-2 w-full text-sm font-medium text-center text-gray-900 bg-gray-100"
                    :class="{'bg-gray-200': state.dataKey === val}"
                  >
                    {{ id }}
                  </button>
                </li>
              </ul>
            </div>
            <div class="flex">
              <div class="flex items-center pr-3 font-semibold">
                AVG:
              </div>
              <ul class="flex divide-x divide-gray-200 shadow flex-1">
                <li
                  v-for="(val, id) in state.comparisonOptions"
                  :key="id"
                  class="w-full"
                >
                  <button
                    @click="state.comparison = val"
                    class="inline-block relative py-2 px-2 w-full text-sm font-medium text-center text-gray-900 bg-gray-100"
                    :class="{'bg-gray-200': state.comparison === val}"
                  >
                    {{ id }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div v-for="(item, index) in state.tables" :key="index" class="space-y-6">
          <div>
            <h1 class="mb-2 text-lg">{{ item.year }}</h1>
            <div class="bg-concrete rounded-md shadow-sm">
              <div class="flex flex-col">
                <datatables-table
                  :columns="item.tableData"
                  :sort-by="{
                    key: 'location_name',
                    direction: 'ASC'
                  }"
                  show-footer
                  :show-mode-toggler="false"
                  :show-column-visibility="false"
                  :show-accessory-row="state.comparison !== null"
                  default-mode="swipe"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import { reactive, computed, watch, markRaw } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import http from '@/services/http.js'
import date from '@/helpers/date.js'
import money from '@/helpers/money.js'
import { sum, totalExportRow } from '@/helpers/datatables.js'
import { formatDecimals } from '@/helpers/utils.js'

import Layout from '@/layouts/Default.vue'
import ReportsNav from '@/components/reports/Nav.vue'
import ReportsToolbar from '@/components/reports/Toolbar.vue'
import TotalsChart from '@/components/reports/TotalsChart.vue'
import { IconLoading } from '@/components/icons'

import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'

import MoneyField from '@/components/datatables/Fields/MoneyShortField.vue'
import PercentageWithColorsField from '@/components/datatables/Fields/PercentageWithColorsField.vue'
import TextField from '@/components/datatables/Fields/TextField.vue'
import DatatablesTable from '@/components/datatables/Table.vue'

import ExportActionButtons from '@/components/datatables/ActionButtons.vue'

export default {
  name: 'ReportsAnnual',

  components: {
    Layout,
    ReportsNav,
    ReportsToolbar,
    IconLoading,
    DatatablesTable,
    ExportActionButtons,
    AlertResponseWarning,
    TotalsChart,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      isLoading: true,
      apiError: false,
      machines: [],
      isOpen: false,
      filters: {
        type: 'all',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        machines: [],
        range: {
          from: date.now().subtract(1, 'day').subtract(1, 'week').format('YYYY-MM-DD'),
          to: date.now().format('YYYY-MM-DD'),
        },
        year_range: {
          from: date.now().subtract(5, 'year').format('YYYY'),
          to: date.now().format('YYYY'),
        },
      },
      data: [],
      totals: [],
      tables: [],
      chartData: {},
      dataKeyOptions: {
        'Sales': 'total_sales',
        'Vends Count': 'vend_count',
      },
      dataKey: 'total_sales',
      comparisonOptions: {
        'None': null,
        '1 year': '1year',
        '3 year': '3year',
        '5 year': '5year',
      },
      comparison: '3year',
    })

    const getComparisonKey = (dataKey, comparison) => {
      if (comparison === null) {
        return null
      }
      if (dataKey === 'total_sales') {
        return {
          '1year': 'previous_1_year_percent_change',
          '3year': 'previous_3_year_percent_change',
          '5year': 'previous_5_year_percent_change',
        }[comparison]
      }
      else {
        return {
          '1year': 'previous_1_year_percent_change_vend_count',
          '3year': 'previous_3_year_percent_change_vend_count',
          '5year': 'previous_5_year_percent_change_vend_count',
        }[comparison]
      }
    }

    const machineIdsString = computed(() => state.filters.machines.join(','))

    const hydrateFiltersFromUrl = () => {
      state.filters.machines = route.query.machines ? route.query.machines.split(',').map((m) => parseInt(m)) : []
      route.query.from ? state.filters.range.from = route.query.from : null
      route.query.to ? state.filters.range.to = route.query.to : null
      route.query.timezone ? state.filters.timezone = route.query.timezone : null
      route.query.type ? state.filters.type = route.query.type : null
      state.selectedMachines = state.machines.filter((machine) => state.filters.machines.includes(machine.location_id))

      route.query.year_from ? state.filters.year_range.from = route.query.year_from : null
      route.query.year_to ? state.filters.year_range.to = route.query.year_to : null
    }

    const updateFilters = () => {
      router.replace({
        query: {
          from: state.filters.range.from,
          to: state.filters.range.to,
          timezone: state.filters.timezone,
          machines: machineIdsString.value,
          type: state.filters.type,
          year_from: state.filters.year_range.from,
          year_to: state.filters.year_range.to,
        }
      })
    }

    const getMachines = async () => {
      hydrateFiltersFromUrl()

      const { data: machines } = await http.get('user/machines')
      const allowedLocations = machines.allowed_locations.filter(m => !m.is_technician)
      state.machines = state.selectedMachines = allowedLocations

      if (allowedLocations.length === 0) {
        state.isLoading = false
        state.apiError = 'You either do not have any machines assigned or you are now allowed to view reports on any of the assigned machines.'
      }
      else if (state.filters.machines.length === 0) {
        state.filters.machines = allowedLocations.map((machine) => machine.location_id)
        updateFilters()
      }
      else {
        getData()
      }
      state.selectedMachines = state.machines.filter((machine) => state.filters.machines.includes(machine.location_id))
    }

    const getData = async () => {
      state.isLoading = true

      const annualStart = date.instance(state.filters.year_range.from).startOf('year').format('YYYY-MM-DD')
      const annualEnd = date.instance(state.filters.year_range.to).endOf('year').format('YYYY-MM-DD')

      try {
        const { data } = await http.get('report/annual', {
          params: {
            start: annualStart,
            end: annualEnd,
            // start: state.filters.range.from,
            // end: state.filters.range.to,
            // timezone: state.filters.timezone,
            location_ids: machineIdsString.value,
            type: state.filters.type,
          }
        })

        state.apiError = false
        state.data = data.data
        state.totals = data.totals

        state.chartData = formatChartData(state.totals)
        state.tables = formatTableData(state.data)
      } catch (e) {
        state.apiError = e
      }

      state.isLoading = false
    }

    watch(
      () => [state.comparison, state.dataKey],
      () => {
        state.chartData = formatChartData(state.totals)
        state.tables = formatTableData(state.data)
      }
    )

    watch(
      () => route.query,
      () => {
        if (route.name === 'ReportsAnnual') {
          // Set timeout to get correct query params from URL
          setTimeout(() => {
            hydrateFiltersFromUrl()
            getData()
          }, 100)
        }
      }
    )

    onBeforeRouteUpdate(() => {
      setTimeout(() => {
        if (Object.keys(route.query).length === 0 && route.query.constructor === Object) {
          updateFilters()
        }
      }, 100)
    })

    getMachines()

    const colorRowExtremes = (result) => {
      return result.map(row => {
        if (row[0].color) {
          const values = row.filter(c => (typeof c.value === 'number' && c.value > 0)).map(c => c.value)
          const max = Math.max(...values)
          const min = Math.min(...values)

          row.forEach(item => {
            const scale = (item.value - min) / (max - min)
            if (scale >= 0) {
              // const r = Math.max(50, Math.round((scale * 900) / 100) * 100) // color range 50 - x00
              const r = 200 + (Math.round((scale * 700) / 100) * 100)
              item.className += ` border-on border-solid border-my-blue-${r}`
            }
          })
        }
        return row
      })
    }

    const addTotalColumn = (result) => {
      result.map(row => {
        if (row[0].total) {
          row.push({
            key: 'total_column',
            className: "text-right text-sm",
            priority: 3,
            footer: sum,
            component: state.dataKey === 'total_sales' ? markRaw(MoneyField) : markRaw(TextField),
            title: 'Totals',
            value: formatDecimals(row.map(c => (c.horizontalTotal && typeof c.value === 'number') ? c.value : 0).reduce((p, n) => p + n)),

            accessoryValue: '-',
            accessoryComponent: markRaw(TextField),
          })
        }
        else {
         row.push({
            key: 'total_column',
            className: "text-right text-sm",
            priority: 3,
            title: 'Totals',
            value: '-',
          })
        }
        return row
      })
      return result
    }

    const formatChartData = (data) => {
      const grouped = []

      const from = parseInt(state.filters.year_range.from)
      const to = parseInt(state.filters.year_range.to)
      for (let year = from; year <= to; year++) {
        grouped.push({
          date: year,
          value: 0
        })
      }

      data.forEach((item) => {
        const [year,] = item.year_month.split('-')
        const date = parseInt(year)
        const index = grouped.findIndex((data) => data.date === date)
        if (index === -1) {
          grouped.push({
            date,
            value: item[state.dataKey],
          })
        } else {
          grouped[index].value += item[state.dataKey]
        }
      })

      return {
        labels: grouped.map(dt => dt.date),
        values: [{
          label: 'Totals',
          data: grouped.map(dt => dt.value),
          fill: false,
        }]
      }
    }

    const formatTableData = (data) => {
      // calc months
      const from = parseInt(state.filters.year_range.from)
      const to = parseInt(state.filters.year_range.to)

      const result = []
      for (let year = to; year >= from; year--) {
        const locations = {}
        let entries = 0
        data.forEach((item, rowIndex) => {
          if (!locations[item.location_id]) {
            const title = Object.keys(state.comparisonOptions).find(k => state.comparisonOptions[k] === state.comparison)
            locations[item.location_id] = [
              {
                key: 'location_name',
                row: rowIndex,
                title: 'Location',
                value: item.location_name,
                className: "font-bold text-left text-sm",
                priority: 1,
                footer: 'Total',
                color: true,
                total: true,

                accessoryValue: `${title} Avg +/-`,
                accessoryComponent: markRaw(TextField),
              }
            ]
            for (let month = 1; month <= 12; month++) {
              locations[item.location_id].push({
                key: `month_${month}`,
                row: rowIndex,
                title: `${year}-${month < 10 ? '0' + month : month}`,
                value: 0,
                className: "text-right text-sm",
                priority: 2,
                footer: sum,
                component: state.dataKey === 'total_sales' ? markRaw(MoneyField) : markRaw(TextField),
                horizontalTotal: true,

                accessoryValue: 0,
                accessoryComponent: markRaw(PercentageWithColorsField),
              })
            }
          }

          const cellIndex = locations[item.location_id].findIndex(obj => obj.title === item.year_month)
          if (cellIndex >= 0) {
            locations[item.location_id][cellIndex].value = Math.round(item[state.dataKey])
            if (state.comparison) {
              const comparisonKey = getComparisonKey(state.dataKey, state.comparison)
              locations[item.location_id][cellIndex].accessoryValue = item[comparisonKey] || 0
              locations[item.location_id][cellIndex].accessoryComponent = markRaw(PercentageWithColorsField)
            }
            entries++
          }
        })

        if (entries > 0) {
          result.push({
            year,
            tableData: addTotalColumn(colorRowExtremes(Object.values(locations))).map(columns => ({
              className: '',
              columns
            })),
          })
        }
      }
      return result
    }

    const exportData = computed(() => {
      let result = []
      if (state.tables.length > 0) {
        for (let [i, item] of state.tables.entries()) {
          const tableData = item.tableData.reduce((all, row) => {
            all.push(row.columns)
            if (state.comparison) {
              const accessory = row.columns.map(column => {
                return {
                  ...column,
                  value: (
                    column.accessoryComponent === PercentageWithColorsField ?
                    `${formatDecimals(column.accessoryValue)}%` : column.accessoryValue
                  )
                }
              })
              all.push(accessory)
            }
            return all
          }, [])

          if (i > 0 && tableData.length > 0) {
            // empty row
            result.push(tableData[0].map(row => {
              return {...row, value: '', className: 'no-border'}
            }))
            // year header row
            result.push(tableData[0].map(row => {
              return {...row, value: row.title, className: 'th-cell'}
            }))
          }
          result = result.concat(tableData)

          // total row
          const totalRow = totalExportRow(item.tableData)
          if (totalRow) {
            result.push(totalRow)
          }
        }
      }
      return result
    })

    const exportFilterDesc = computed(() => {
      let value = `${state.dataKey === 'total_sales' ? 'Annual Total Sales' : 'Annual Vends Count'}\n`
      if (state.filters.machines.length > 0) {
        const machineNames = state.filters.machines.map(machine => {
          return state.machines.find(m => m.location_id === machine)?.name
        })
        value += `Machines: ${machineNames.join(', ')}\n`
      }
      if (state.filters.type) {
        value += `Type: ${state.filters.type}\n`
      }
      if (state.filters.range) {
        value += `Year Range: ${state.filters.year_range.from} - ${state.filters.year_range.to}`
      }
      return value
    })

    return {
      state,
      money,
      updateFilters,
      exportData,
      exportFilterDesc,
    }
  }
}
</script>

<style>
  tbody td.border-on { @apply border-b-4; }
  tbody td.border-my-blue-50 { @apply border-blue-50; }
  tbody td.border-my-blue-100 { @apply border-blue-100; }
  tbody td.border-my-blue-200 { @apply border-blue-200; }
  tbody td.border-my-blue-300 { @apply border-blue-300; }
  tbody td.border-my-blue-400 { @apply border-blue-400; }
  tbody td.border-my-blue-500 { @apply border-blue-500; }
  tbody td.border-my-blue-600 { @apply border-blue-600; }
  tbody td.border-my-blue-700 { @apply border-blue-700; }
  tbody td.border-my-blue-800 { @apply border-blue-800; }
  tbody td.border-my-blue-900 { @apply border-blue-900; }

  tbody td.text-white-i {
    @apply text-white !important;
  }
</style>
