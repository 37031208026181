<template>
  <span
    :class="{
      'text-red-500 font-semibold': roundedValue < 0,
      'text-green-400 font-semibold': roundedValue > 0,
    }"
  >
    {{ roundedValue }}%
  </span>
</template>

<script>
import { computed } from 'vue'
import { formatDecimals } from '@/helpers/utils.js'

export default {
  name: 'DatatablesFieldsPercentageWithColorsField',

  props: ['value'],

  setup(props) {
    const roundedValue = computed(() => {
      const value = parseFloat(props.value)

      return formatDecimals(value ? value : 0)
    })

    return {
      roundedValue,
    }
  }
}
</script>
